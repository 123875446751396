import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { LoginService } from '../login.service'
import { NotificationService } from '../notification.service'
import { NgxSpinnerService } from "ngx-spinner";  

//declare var ConfettiGenerator : any
@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
  @ViewChild('myDiv')
  myDiv!: ElementRef;
  registerForm = new FormGroup ({
    name: new FormControl(),
    haveTafaAccount: new FormControl(),
    email: new FormControl(),
    howDiduKnow: new FormControl(),
    objective: new FormControl(),
    language: new FormControl()
  });
  loading = false;
  submitted = false;
  mode: any = 'register';
  registerData: any;
  showSuccessPage: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private _Activatedroute:ActivatedRoute,
    private router: Router,
    public loginService: LoginService,
    private notifyService : NotificationService,
    private SpinnerService: NgxSpinnerService ) { 
      
    }

    ngOnInit() {
      this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        haveTafaAccount: ['', [Validators.required]],
        email: ['', [Validators.required]],
        howDiduKnow: ['', [Validators.required]],
        objective: ['', [Validators.required]],
        language: ['', [Validators.required]],
    },
    );
   }

   goto(link: any){
    window.open(link);
   }

   onRegisterSubmit(){
   }

  get r() { return this.registerForm.controls; }

  close(){
    this.showSuccessPage = false;
  }
}

