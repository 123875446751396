<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,900&display=swap" rel="stylesheet">
    <div class="content" *ngIf="mode == 'register'">
        <div class="row" *ngIf="!showSuccessPage" class="first-row"> 
            <div class="col-lg-4 signin" >
                <br>
                <br>
                <img src="assets/imgs/weekly.jpg" width="153" style="margin-bottom: 24px;animation: none;width: 100%;border:1px solid #222" sizes="(max-width: 479px) 42vw, (max-width: 1439px) 152.98611450195312px, 11vw" srcset="assets/imgs/weekly.jpg 500w, assets/imgs/weekly.jpg 800w, assets/imgs/weekly.jpg 1080w, assets/imgs/weekly.jpg 1600w" >
                <h2 style="text-align: center !important;">Meeting Registration</h2>
                <!-- <p style="text-align: center;">Tafabot Weekly Training</p> -->
                <br>
                <div class="form">
                    <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="name">Fulll Name </label>
                                    <input type="email" placeholder="Your Full Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && r.name.errors }" />
                                    <div *ngIf="submitted && r.name.errors" class="invalid-feedback">
                                        <div *ngIf="r.name.errors.required" class="error">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="haveTafaAccount" style="display:block;margin-bottom: 0;">Do you have a Tafabot account? </label>
                                    <select formControlName="haveTafaAccount" class="form-control" placeholder="Choose one">
                                        <option disabled>Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <div *ngIf="submitted && r.haveTafaAccount.errors" class="invalid-feedback">
                                        <div *ngIf="r.haveTafaAccount.errors.required" class="error">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="email" *ngIf="r.haveTafaAccount && r.haveTafaAccount.value == '1'">Your Tafabot Email </label>
                                    <label for="email" *ngIf="r.haveTafaAccount.value == '0' || !r.haveTafaAccount || r.haveTafaAccount.value == ''">Your Email</label>
                                    <input type="text" placeholder="Your Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && r.email.errors }" />
                                    <div *ngIf="submitted && r.email.errors" class="invalid-feedback">
                                        <div *ngIf="r.email.errors.required" class="error">Email is required</div>
                                        <div *ngIf="r.email.errors.email" class="error">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="howDiduKnow">How did you get to know about Tafabot? </label>
                                    <select formControlName="howDiduKnow" class="form-control" placeholder="Choose one">
                                        <option disabled>Choose one..</option>
                                        <option value="Someone told me">Someone told me</option>
                                        <option value="I Searched on Google">I Searched on Google</option>
                                        <option value="Youtube Videos">Youtube Videos</option>
                                        <option value="Youtube Ads">Youtube Ads</option>
                                        <option value="Ads on a Website">Ads on a Website</option>
                                        <option value="Social Media Ads">Social Media Ads</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <div *ngIf="submitted && r.howDiduKnow.errors" class="invalid-feedback">
                                        <div *ngIf="r.howDiduKnow.errors.required" class="error">This field is required</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="objective">What's your objective in attending this meeting?</label>
                                    <select formControlName="objective" class="form-control" placeholder="Choose one">
                                        <option disabled>Choose one..</option>
                                        <option value="I am new to crypto. I want to learn how to sign up and begin to trade">I am new to crypto. I want to learn how to sign up and begin to trade</option>
                                        <option value="I'm a Networker. I want to understand the Affiliate (referral) program of Tafabot"> I'm a Networker. I want to understand the Affiliate (referral) program of Tafabot</option>
                                        <option value="I want to understand how to configure my bots">I want to understand how to configure my bots</option>
                                        <option value="I want to understand how to use the software generally">I want to understand how to use the software generally</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <div *ngIf="submitted && r.objective.errors" class="invalid-feedback">
                                        <div *ngIf="r.objective.errors.required" class="error">This field required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="language">What language do you prefer for the live trainings?</label>
                                    <select formControlName="language" class="form-control" placeholder="Choose one">
                                        <option disabled>Choose one..</option>
                                        <option value="English">English</option>
                                        <option value="French">French</option>
                                        <option value="Vietnamese">Vietnamese</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="Russian">Russian</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="Chinese">Chinese</option>
                                    </select>
                                    <div *ngIf="submitted && r.language.errors" class="invalid-feedback">
                                        <div *ngIf="r.language.errors.required" class="error">This field required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style="text-align: left;margin: 0px 0 8px 10px;
                        font-size: 12px;
                        color: #eee;display: none;">By clicking Signup, You agree to our <span style="text-decoration: underline;cursor: pointer " (click)="goto('https://tafabot.com/terms-and-conditions')">Terms & Conditions.</span> </p>
            
                        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
                            <p style="color: white">Loading... </p>  
                        </ngx-spinner> 
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary" style="cursor: pointer;">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="showSuccessPage" class="congrats">
            <div class="card">
                <div style="border-radius:170px; height:130px; width:130px; background: #F8FAF5; margin:0 auto;">
                  <img src="assets/imgs/success.gif" style="border-radius: 50%;">
                  <h1 style="color: #00e106;width: 400px;margin: 16px 0 0 -79px;">Registration Successful !!</h1>
                  <br>
                </div>
                <div class="division-1" style="position: relative;z-index: 10000;">
                    <!-- <h3 class="beat">Login to Tafabot app for more information</h3> -->
                    <img src="assets/imgs/logo.jpg" style="width: 120px;height: 120px;border: 1px solid #222;">
                    <div class="division-2">
                        <h2 class="beat">Your Registration for Tafabot training is Successful. You will be notified via email of the zoom training date and time.
                        </h2>
                    </div>
                    <button (click)="goto('https://tafabot.com')" type="button" style="display: block;margin: auto;background: transparent;box-shadow: none;border: none;margin-top: -16px;margin-bottom: -16px;"> <p style="color: #fff;font-size: 12px;">Visit <span style="text-decoration: underline;cursor: pointer;font-weight: 500;z-index: 10000;">https://tafabot.com/</span>  for more info.</p></button> 
                    <br>
                    <button class="btn btn-primary" style="cursor: pointer;max-width: 200px;height: 48px;font-size: 13px;margin: auto;display: block;" (click)="close()">
                        Close
                    </button>
                </div>
               
            </div>
            <br>
            <br>

        </div>
              <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
    </div>
    
