import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";  
import { NotificationService } from '../notification.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { ModalService } from '../_modal';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  data: any;
  submitted = false;
  loading = false;
  isSignUpSuccess: boolean = false;
  name: any = '';
  userData: any;
  emailVerification: boolean = false;
  verificationCode: any;
  registerForm = new FormGroup ({
    name: new FormControl(),
    email: new FormControl(),
    newPassword: new FormControl(),
    confirmPassword: new FormControl(),
    country: new FormControl(),
    howDid : new FormControl(),
    captcha: new FormControl(),
  });
  registerData: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'color': '#FFF'
    }
  };
  tafaData: any;
  stats: any;
  videoClicked1: boolean = false;
  videoClicked : boolean = false;
  video: any;
  captchatxt: any;
  validCaptcha: boolean = false;
  showVideo: boolean = true;

  constructor(private formBuilder: FormBuilder, private modalService: ModalService, private _Activatedroute:ActivatedRoute, private location: Location, private SpinnerService: NgxSpinnerService, private notifyService : NotificationService, public loginService: LoginService, private router: Router,private route: ActivatedRoute) {
  }
  
  ngOnInit(): void {
    // this.SpinnerService.show();
    // setTimeout(() => {
    //   this.SpinnerService.hide();
    // }, 800);
    this.getStats();
    this._Activatedroute.queryParams.subscribe(params => {
      const code = params['inviteCode']; 
      if(code){
        this.loginService.inviteCode = code;
        this.getData();
      }
      else{
        this.loginService.inviteCode = 'NYZDEH';
        this.getData();
      }     
    }, error => {
      this.loginService.inviteCode = 'NYZDEH';
      this.getData();
    });
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required, , Validators.minLength(5)]],
      email: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      country: ['', [Validators.required]],
      howDid: ['', [Validators.required]], 
      captcha : ['', [Validators.required]], 
  },
  {
    validator: MustMatch('newPassword', 'confirmPassword')
  });  
  window.scroll(0,0);
  setTimeout(() => {
    let video = document.getElementById('video-el') as HTMLVideoElement;
    video.play();
    //video.muted = true;
  }, 1500);
  }

  openSignupForm(){
    let video = document.getElementById('video-el') as HTMLVideoElement;
    video.muted = true;
    video.pause();
    this.modalService.open('custom-modal');
    this.captchaText();
    //this.modalService.close(id);
  }

  captchaText(){
    let alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let emptyArr = [];
    for (let i = 1; i <= 5; i++) {
        emptyArr.push(alphaNums[Math.floor(Math.random() * alphaNums.length)]);
    }
    var c = emptyArr.join('');
    this.captchatxt = c;
    console.log(this.captchatxt);
    this.validCaptcha = false;
}

  playvideo(index: any){
    this.video = index;
    console.log(this.video);
    let video = document.getElementById('video-el') as HTMLVideoElement;
    video.muted = true;
    video.pause();
    if(index == '1'){
      this.videoClicked = true;
      this.modalService.open('custom-modal2');
      setTimeout(() => {
        let vid = document.getElementById('video1') as HTMLVideoElement
        vid.play();
      }, 500);
    }
    else{
      this.videoClicked1 = true;
      this.modalService.open('custom-modal3');
      setTimeout(() => {
        let vid = document.getElementById('video2') as HTMLVideoElement
        vid.play();
      }, 500);
      
    }
  }

  cancelVideo(id: any){
    if(id == 'custom-modal2'){
      let vid = document.getElementById('video1') as HTMLVideoElement
      vid.pause();
    }
    else{
      let vid = document.getElementById('video2') as HTMLVideoElement
      vid.pause();
    }
    let video = document.getElementById('video-el') as HTMLVideoElement;
    video.play();
    video.muted = false;
    this.modalService.close(id);
  }

  cancelModel(id: any){
    let video = document.getElementById('video-el') as HTMLVideoElement;
    video.play();
    video.muted = false;
    this.modalService.close(id);
  }

  getData(){
    this.loginService.getUserData(this.loginService.inviteCode)
      .then(
        (data: any) => {
          this.SpinnerService.hide();
          if(data && data.status == 1){
            this.userData = data.data;
          }
          else{
            this.userData = data.data;
          }
        })
  }

  getStats(){
    this.loginService.getSats()
      .then(
        (data: any) => {
          console.log(data);
          console.log(data.data);
          this.SpinnerService.hide();
          if(data && data.status == 1){
            this.stats = data.data['User Details'];
          }
          else{
            this.stats = data.data;
          }
        })
  }

  menu(){
    const box = document.getElementById('popup-mobile-menu') as HTMLElement;
    if(box != null)
    console.log(box.classList)
    box.classList.add("active"); 
    console.log('ee');       
  }

  menuClose(){
    const box = document.getElementById('popup-mobile-menu') as HTMLElement;
    if(box != null)
    console.log(box.classList)
    box.classList.remove("active"); 
    console.log('ee');   
  }

  sendVerificationCode(){
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log(this.registerForm);
        return;
    }
    console.log(this.r.captcha.value);
    let cal = this.r.captcha.value;
    if(this.captchatxt == cal.toUpperCase()){
      this.SpinnerService.show();  
      this.loading = true;
      this.loginService.sendRegisterCode(this.r.email.value)
        .then(
            data => {
              this.data = data;
              this.SpinnerService.hide();
              this.loading = false;
              if(this.data && this.data.status && this.data.status == 1){
                this.emailVerification = true;
                this.notifyService.showSuccess(this.data.data, 'Success')
              }
              else if(this.data && this.data.status && this.data.status == 0){
                this.notifyService.showError(this.data.data, 'FAILED');
              }
              else{
                this.notifyService.showError(this.data.data, 'FAILED')
              }              
        });
    }
    else{
      this.notifyService.showWarning('Invalid Captcha', 'Error');
    }
  }

  openLink(link: any){
    window.open(link)
  }

  changeTheme(type: any){
    console.log('changeTheme');
    let toggler = document.querySelector('.theme-toggler') as HTMLElement,
    root = document.getElementsByTagName('body')[0],
    currentTheme = localStorage.getItem('theme') || 'dark'
    
    //root.setAttribute('data-theme', currentTheme);
    if(type == 'light'){
      root.classList.remove("active-dark-mode"); 
      root.classList.add("active-light-mode"); 
      localStorage.setItem('theme', 'light')
      const note = document.querySelector('.main-content') as HTMLElement;
      note.style.background = '#fff';
      const note1 = document.querySelector('.rwt-testimonial-area') as HTMLElement;
      note1.style.background = '#fff';
      const note2 = document.getElementById('switch') as HTMLElement;
      note2.classList.add('active');
      const note3 = document.getElementById('switch1') as HTMLElement;
      note3.classList.remove('active');
      note3.style.display = 'contents';
    }
    else{
      root.classList.remove("active-light-mode"); 
      root.classList.add("active-dark-mode"); 
      localStorage.setItem('theme', 'dark')
      const note = document.querySelector('.main-content') as HTMLElement;
      note.style.background = '#060606';
      const note1 = document.querySelector('.rwt-testimonial-area') as HTMLElement;
      note1.style.background = '#060606';
      const note2 = document.getElementById('switch1') as HTMLElement;
      note2.classList.add('active');
      const note3 = document.getElementById('switch') as HTMLElement;
      note3.classList.remove('active');
    }
  }

  onRegisterSubmit(){
     this.loginService.register(this.r.email.value, this.r.name.value, this.r.newPassword.value, this.r.country.value, this.loginService.storedOTP, this.loginService.inviteCode, this.r.howDid.value)
      .then(
        data => {
          this.registerData = data;
          this.loading = false;
          console.log('test', this.registerData);
          if(this.registerData && this.registerData.status && this.registerData.status == 1){
            this.notifyService.showSuccess(this.registerData.data, "Success");
            this.loginService.customerEmail = this.r.email.value;
            this.showVideo = false;
            //this.SpinnerService.hide(); 
            sessionStorage.user = JSON.stringify({email: this.r.email.value});
            this.loginService.isLoggedIn = true;
            this.modalService.close('custom-modal');
            //let video = document.getElementById('video-el') as HTMLVideoElement;
            //video.play();
            //video.muted = false;
            window.scroll(0,0);
          }
          else if(this.registerData && this.registerData.status == 0){
            if(this.registerData.data && this.registerData.data == 'Incorrect Code!'){
              this.SpinnerService.hide();
              this.loading = false;
              this.notifyService.showError(this.registerData.data, 'ERROR');
            }
            else if(this.registerData.data && this.registerData.data == 'INVALID USERNAME FORMAT'){
              this.SpinnerService.hide();
              this.notifyService.showError(this.registerData.data, 'ERROR');
              this.loading = false;
              this.emailVerification = false;
            }
            else{
              this.notifyService.showError(this.registerData.data, "ERROR");
              this.SpinnerService.hide();
              this.loading = false;
              this.emailVerification = false;
            }
          }
        },
        error => {
          this.notifyService.showError("Error", error);
        this.loading = false;
        this.SpinnerService.hide();  
      });
  }

  onOtpChange($event: any){
     
    if($event.length == 5){
      this.submitted = true;
      this.loginService.storedOTP = $event;
      this.loading = true;
      this.SpinnerService.show();
      this.onRegisterSubmit();
    }  
  }

  showEmailVerification(){
    this.emailVerification = false;
  }


  get r() { return this.registerForm.controls; }
    
  goto(link: any){
    console.log('test');
    window.open(link);
  }

  copyLink(text:string){
    this.SpinnerService.show();
    setTimeout(() => {
      this.SpinnerService.hide();
      navigator.clipboard.writeText(text).then().catch(e => console.error(e));
      this.notifyService.showSuccess("Copied to clipboard", "Success");
    }, 500);
  }

  goBack(){
    this.location.back();
  }
}

function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }

}
