import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { LoginRegisterComponent } from './login-register/login-register.component';

const routes: Routes = [
  { path: "signup", component: LandingComponent},
  { path: "*/:inviteCode", component: LandingComponent},
  { path: "", component: LandingComponent },
  { path: '**', component: LandingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
