import { Component} from '@angular/core';
import { LoginService } from './login.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'Tafabot';
  constructor(public loginService: LoginService){

  }
  onActivate(event: any) {
    window.scroll(0,0);
  }
}


